.cart-information {
  .order-list {
    margin-top: 1em;
    padding: 1em 1em;
    background-color: $light-bg-color;
  }

  .final-row {
    display: flex;
    align-items: flex-end;
    .total-costs {
      margin-left: auto;
      margin-top: 1em;

      td,
      th {
        padding: 0.25em 0.5em;
        font-weight: normal;
        text-align: right;
      }

      td {
        text-align: left;
      }

      table .split {
        background-color: $lighter-grey-bg-color;
      }
    }
  }

  .payment-info {
    color: red;
    text-align: end;
  }
}
