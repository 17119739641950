.transactions-page {
  .transactions-container {
    margin: 0 0 1rem 0;
  }

  .transactions-row {
    display: flex;
    width: 100%;
  }

  .tooltip {
    width: 20rem;
  }

  .transactions-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    label {
      margin: 0 1em 0 0;
      white-space: nowrap;
    }

    div {
      justify-content: space-between;
    }

    @media (max-width: $mobile-width) {
      flex-direction: column;
      align-items: center;

      div {
        width: 100%;
      }

      button {
        margin-top: 1em;
      }
    }
  }

  table {
    tbody {
      tr {
        transition: all 0.3s ease;

        &:nth-child(2n-1) {
          background-color: $light-bg-color;
        }

        &:hover {
          color: $light-text-color;
          background-color: $lighter-blue-color;
        }
      }
    }

    th,
    td {
      padding: 8px;
    }
  }
}
