.toast {
  z-index: 998;
  position: fixed;
  display: flex;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid $light-border-color;
  border-radius: 4px;
  background-color: $light-bg-color;
  padding: 1em;
  animation: up;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  transition: 0.2s ease;
  bottom: 10px;

  &:hover {
    transform: scale(1.025) translateX(calc(-50% / 1.025));
  }

  .toast-icon {
    padding-left: 0.5em;
    padding-right: 1em;
  }

  &.success {
    background-color: $success-color;
    border: 1px solid $success-color;
  }

  &.warning {
    background-color: $warning-color;
    border: 1px solid $warning-color;
  }

  &.error {
    background-color: $danger-color;
    border: 1px solid $danger-color;
    color: $light-text-color;
  }

  .close-button {
    display: flex;
    padding: 0.5em 0.75em;
    cursor: pointer;

    .icon-button {
      margin-left: 1em;
    }
  }

  @keyframes up {
    0% {
      bottom: -100px;
    }
    100% {
      bottom: 10px;
    }
  }
}
