.accordion {
  margin-bottom: 1em;

  &__title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    background-color: $darkest-grey-color;
    color: $light-text-color;
    font-size: 1.4em;
    padding: 0.4em 0.6em;
    transition: all 0.3s ease;

    &:hover {
      background-color: $dark-grey-color;
    }
  }

  &__content {
    display: none;
    background-color: $light-bg-color;
    padding: 1em;

    &__expanded {
      display: block;
    }
  }
}
