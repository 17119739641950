.maintenance-page {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: $main-theme-color;
  opacity: 0.95;

  ::selection {
    background-color: $lighter-blue-color;
  }

  .main-content {
    margin: auto;
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logos-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      img {
        margin-bottom: 3em;
      }
    }

    .maintenance-img {
      width: 100%;
    }

  }
}

@media (max-width: $laptop-width) {
  .maintenance-page {
    .main-content {
      flex-direction: column;
      width: 80%;

      .logos-section,
      .form-wrapper {
        margin-top: 1em;
        width: 100%;
      }
    }
  }
}
