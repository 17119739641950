.order-review-page {
  margin-bottom: 1em;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  table th:not(:first-child) {
    text-align: center;
  }

  .submit-order {
    display: flex;
    margin-top: 1em;
    button {
      margin: auto;
    }
  }

  .ship-origin-list {
    ol {
      padding: 0;
    }

    li {
      display: inline;
      font-weight: bold;
      list-style: none;
      :not(:last-child)::after {
        content: ", ";
        color: "white";
      }
    }
  }

  @media (max-width: $mobile-width) {
    .order-review-page {
      font-size: 0.9em;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .modal-template {
    padding: 2em;
    background-color: $light-bg-color;
    border: 1px solid $light-border-color;
    height: fit-content;
    margin: auto;
  }
}
