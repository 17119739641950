.branch-selection-page {
  .container {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
  }

  .modal {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    .modalContent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: scroll;
      background-color: $light-bg-color;
      padding: 1.5em;
      text-align: center;
    }
  }

  .office {
    flex-basis: calc(25% - 0.75em);
    padding: 10px;
    background-color: $light-bg-color;
    margin-right: 1em;
    margin-bottom: 1em;
    border-radius: 5px;
  }

  .office:nth-child(4n) {
    margin-right: 0;
  }

  .office:hover {
    background: $light-blue-color;
    cursor: pointer;
    color: $light-text-color;
  }

  .office.selected {
    background-color: $light-blue-color;
    color: $light-text-color;
  }

  .buttons {
    margin-top: 1.5em;
  }
}
