.date-picker {
  position: relative;
  z-index: 1;

  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.5em 1.5em;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
