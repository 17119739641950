.sidebar-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  opacity: 1;

  &.collapsed {
    opacity: 0;
    z-index: -1;
  }

  .sidebar {
    width: 30%;
    transition: 0.5s ease;
    z-index: 1001;

    &.collapsed {
      transform: translateX(-100%);
    }

    @media (max-width: $mobile-width) {
      width: 80%;
    }

    background: $darkest-grey-color;
    color: $light-text-color;
    padding: 0px !important;
    user-select: none;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;


    .change-office-container {
      padding: 15px;
      color: $light-text-color;
      margin-top: 10px;

      button {
        margin-top: 10px;
      }
    }

    .sidebar-menu {
      .expand-icon {
        display: inline;
        float: right;
        margin-left: auto;
      }

      .menu-label {
        padding: 1em;
      }

      .sub-menu {
        background-color: $darker-grey-color;
        .selected {
          background-color: $dark-grey-color;
        }

        a {
          display: flex;
          padding: 5px 15px;
          color: $light-text-color;
          transition: 0.3s ease;

          &.icons {
            padding: 5px;
          }

          &:hover {
            background-color: $dark-grey-color;
            text-decoration: none;
          }
        }
      }
    }
  }

  .empty-space {
    width: 70%;
  }
}
