.custom-badge {
  padding: 0.25em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.75em;
  text-transform: uppercase;
  white-space: nowrap;

  &.new {
    background-color: #d6b24a;
  }

  &.old {
    background-color: #ff5235;
  }

  &.promo {
    background-color: #3ca7ac;
  }
}
