.extra-info {
  margin-top: 1em;
  width: fit-content;

  p {
    margin: 0;
  }

  section {
    margin-bottom: 2em;
  }

  .warning {
    color: red;
    margin-bottom: 0;
  }

  .loader-button-wrapper {
    width: fit-content;
  }
}
