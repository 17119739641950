.airbag-error-modal {
  background-color: $light-bg-color;
  height: fit-content;
  margin: auto;
  padding: 2em;
  align-items: center;
  border: 1px solid $light-border-color;

  button {
    width: 100%;
    margin: 1em 0;
  }
}
