.search-bar {
  form {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      position: absolute;
      right: 1em;
    }
  }
}
