.reset-password-page {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: $main-theme-color;
  opacity: 0.95;

  form {
    margin: auto;
    padding: 2em;
    background-color: $light-bg-color;
  }
}
