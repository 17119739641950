.paginator {
  margin: 1em 0 0 0;
  display: flex;
  flex-wrap: wrap;

  span {
    margin-right: 1em;
  }

  .paginator-link {
    padding: 0.5em 1em;
    margin-right: 1em;
    border-radius: 4px;
    margin-bottom: 1em;

    border: 1px solid $grey-text-color;

    color: var(--fb-dark-main);
    background-color: $light-bg-color;

    &.selected {
      color: $light-text-color;
      background: $main-theme-color;
      border: 1px solid $main-theme-color;
    }
  }
}
