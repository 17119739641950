.popup-modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  color: $dark-text-color !important;
  background-color: $light-bg-color;
  padding: 1.5em;
  text-align: center;
}

.popup-modal-buttons {
  margin-top: 1.5em;
}

  /* Styles for EachTransaction */
  .printableTransactionHeader {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }