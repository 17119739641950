.paymentTableWrapper {
  padding: 1rem 1rem 1rem;
  margin: 1rem 0rem;
  background-color: $light-bg-color;
  width: 25%;
  min-width: 465px;
}

.paymentTableWrapper select:focus {
  outline: none !important;
  box-shadow: none;
}

.paymentTable table {
  width: 50%;
}

.paymentTable th {
  padding-bottom: 0.5rem;
}

.paymentTable td {
  padding: 0 0 0.5rem 0.5rem;
}


.bank-transfer-info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  background-color: $light-bg-color;
  border: 1px solid $light-border-color;

  .paymentTableWrapper {
    width: 100%;
  }

  .actions {
    display: flex;
    margin-top: 1.5em;
    justify-content: right;
    align-items: center;
    padding: 1rem;

    a {
      margin-left: auto;
    }

    button {
      margin-left: 1em;
      width: 11.5em;
    }
  }

}
