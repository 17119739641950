.cart-page {
  .cart-top-bar {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: $laptop-width) {
      flex-direction: column;
      align-items: flex-start;

      .action-group {
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
    }
  }

  .cart-modal-template {
    background-color: $light-bg-color;
    margin: auto;
    padding: 2em;
    border: 1px solid $light-border-color;
    text-align: center;
  }

  .icon-and-text-button-wrapper {
    height: 100%;
    width: 85px;
    height: 85px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;

      span {
        font-size: 0.85em;
        margin-top: 0.75em;
      }
    }
  }
}
