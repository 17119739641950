.split-failed-error {
  margin: auto;
  padding: 2em 5em;
  height: fit-content;
  background-color: $light-bg-color;
  text-align: center;
  border: 1px solid $light-border-color;

  button {
    width: 100%;
  }
}