.media-library {
  .library-links {
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    a {
      font-size: 1.3em;
      color: $grey-text-color;
      margin: 1em 0;
    }
  }
}
