::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: $light-bg-color;
  border-radius: 4px;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $main-theme-color;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $body-bg-color;
  height: 100%;
}

#root {
  height: 100%;

  @media (max-width: $mobile-width) {
    min-height: 100vh;
    height: unset;
  }
}

@media print {
  body * {
    display: none;
  }
}

li:focus,
button:focus {
  outline: none;
}

.hide {
  display: none;
}

.waiting-page {
  height: 100%;
  background-color: $main-theme-color;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

  .app-main-content {
    width: 100%;
    padding: 0 1em;
  }
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 100%;
  overflow: scroll;
}
