.dropdown {
  position: relative;
  z-index: 1;

  .collapsible-content {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $light-border-color;
    position: absolute;
    min-width: 13rem;
    right: 0;

    background-color: $light-bg-color;
    color: $dark-text-color;
    text-align: right;

    .dropdown-link {
      display: block;
      color: $dark-text-color;
      padding: 0.2em 0.5em;
      transition: 0.5s ease;

      &:hover {
        background-color: $lighter-grey-bg-color;
        text-decoration: none;
      }
    }
  }
}
