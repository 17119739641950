.order-uploader {
  .utils {
    .uploader {
      display: flex;

      .util {
        margin-right: 0.5em;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: 400px;
    height: fit-content;
    text-align: center;
    background-color: $light-bg-color;
    padding: 2em;
    border: 1px solid $lighter-grey-bg-color;
    border-radius: 3px;

    .failedContent {
      color: red;
    }

    .loadingSpinnerWrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
    }
  }
}
