.product-card-wrapper {
  color: $darkest-grey-color;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  margin-bottom: 2em;

  width: calc(100% / 7 - 12em / 7);

  &:nth-child(7n) {
    margin-right: 0;
  }

  @media (max-width: $mobile-width) {
    width: calc(100% / 2 - 2em / 2);

    &:nth-child(7n) {
      margin-right: 2em;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .double-badge {
    display: flex;
    flex-wrap: wrap;

    .custom-badge {
      margin-bottom: 0.5em;
    }
  }

  .product-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
  }

  &:hover {
    .product-card-overlay {
      background-color: rgba(255, 255, 255, 0.6);
    }
    color: $darkest-grey-color;
    text-decoration: none;
  }

  .product-code-and-brand {
    margin-top: 3px;
    display: flex;

    span {
      color: $grey-text-color;
      margin-left: auto;
    }

    @media (max-width: $laptop-width) {
      flex-direction: column;

      span {
        margin: 0;
      }
    }
  }
}
