.customer-info-container {
  .info-table {
    .field {
      display: inline-block;
      width: 50%;

      label {
        font-weight: bold;
        display: inline-block;
        width: 30%;
      }

      @media (max-width: $mobile-width) {
        display: block;
        width: 100%;

        label {
          width: 50%;
        }
      }
    }
  }
}
