.order-result {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $light-bg-color;
  border: 1px solid $light-border-color;

  .actions {
    display: flex;
    margin-top: 1.5em;
    justify-content: right;
    align-items: center;

    a {
      margin-left: auto;
    }

    button {
      margin-left: 1em;
      width: 11.5em;
    }
  }

  .main-content {
    padding: 2em;

    .success {
      h1,
      h2 {
        color: $grey-text-color;
        font-weight: normal;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
        color: green;
      }
    }

    .fail {
      h1,
      h2 {
        color: red;
        font-weight: normal;
      }
    }
  }
}
