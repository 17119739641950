.product-details {
  margin: 1em 0;
  width: 100%;
  .product-information {
    margin: 1.5em 0;

    img {
      margin-bottom: 1.5em;
    }

    h5 {
      font-weight: normal;
      margin-bottom: 0.5em;
      &:last-child {
        color: $grey-text-color;
      }
    }
  }

  .product-description {
    font-size: 0.95em;
  }

  .left-content {
    width: 17%;
  }

  .right-content {
    margin-left: auto;
    width: 80%;
  }

  @media (max-width: $laptop-width) {
    .left-content {
      width: 30%;
    }

    .right-content {
      width: 70%;
    }
  }

  @media (max-width: $mobile-width) {
    .left-content {
      width: 50%;
    }

    .right-content {
      padding-left: 0;
      width: 100%;
    }
  }
}
