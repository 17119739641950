@mixin selected-badge {
  content: "SELECTED";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background: $success-color;
  color: $dark-text-color;
}

.bogoPromotion {
  background-color: $light-bg-color;
  padding: 1em;

  .giftItemOptions {
    display: flex;
    flex-wrap: wrap;

    .giftItem {
      position: relative;
      cursor: pointer;
      flex-basis: 120px;
      margin-right: 1em;
      padding: 1em 1em 1em 0;
      text-align: center;
      border: 1px solid transparent;
      transition: all 0.3s ease;

      &.selected {
        &:before {
          @include selected-badge;
        }
      }

      &.gift-item-hover:hover {
        &:before {
          @include selected-badge;
        }
      }

      &.gift-item-no-hover {
        cursor: default;
      }

      &:last-child {
        margin-right: 0;
      }

      &__image {
        img {
          width: 120px;
        }
      }

      &__description {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .giftItemColorOptions {
    display: flex;

    .giftItemColor {
      position: relative;
      cursor: pointer;
      flex-basis: 80px;
      margin-right: 1em;
      padding: 0.8em;
      text-align: center;
      border: 1px solid transparent;
      transition: all 0.3s ease;

      &.selected,
      &:hover {
        &:before {
          @include selected-badge;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &__image {
        img {
          width: 80px;
        }
      }
    }
  }

  .giftItemColorDetails {
    overflow-x: scroll;

    table {
      tr {
        th:first-child {
          min-width: 100px;
          padding-right: 1em;
        }
      }
    }

    button {
      margin-top: 1em;
    }
  }
}
