.order-split-modal {
  background-color: $light-bg-color;
  padding: 2em 2em 1em 2em;
  height: fit-content;
  margin: auto;
  border: 1px solid $light-border-color;

  button {
    width: 100%;
    margin: 1em 0;

    &:last-child {
      margin-top: 0;
    }
  }
}
