.addressTableWrapper {
  padding: 2em;
  background-color: $light-bg-color;
  border: 1px solid $light-blue-color;
}

.addressTableWrapper select:focus {
  outline: none !important;
  box-shadow: none;
}

.addressTable table {
  width: 90%;
}

.addressTable th {
  padding-bottom: 1em;
}

.addressTable td {
  padding: 0 0 1em 1em;
}
