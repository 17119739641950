.cart-item {
  height: 90px;
  background-color: $light-bg-color;
  border-bottom: 1px solid $lighter-border-color;
  transition: 0.3s ease;

  .cursor {
    cursor: pointer;
  }

  .main {
    font-weight: bold;
  }

  &:hover {
    background-color: $lighter-border-color;
  }

  td {
    text-align: center;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &.highlight {
    background-color: $danger-color;
    color: white;
  }

  .button-container {
    display: flex;
    margin-right: 0.5em;

    .icon-button:last-child {
      margin-left: 1em;
    }

    @media (max-width: $laptop-width) {
      flex-direction: column;

      .icon-button:last-child {
        margin-left: 0;
        margin-top: 1em;
      }
    }
  }
}

.cart-item-modal-template {
  padding: 2em;
  background-color: $light-bg-color;
  height: fit-content;
  margin: auto;
  border: 1px solid $light-border-color;
}
