.size-detail-table {
  text-align: center;

  td {
    min-width: 125px;
  }
}

.auStock-header {
  background: white;
  cursor: pointer;
}

.auStock-Icon {
  margin-right: 10px;
}
