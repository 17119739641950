.icon-wrapper {
  cursor: pointer;
  display: flex;
  border: 2px solid transparent;
  min-width: 225px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;

  &.is-selected {
    border: 2px solid $light-blue-color;
  }

  &.transparent-on-not-selected {
    opacity: 0.5;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  .icon-wrapper-icon {
    padding: 0.9em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-wrapper-text {
    padding: 0.9em;
    font-size: 1.08em;
    display: flex;
    flex-direction: column;

    .header {
      color: rgba(79, 82, 82, 0.73);
      font-weight: bold;
    }

    .subtext {
      font-weight: bold;
    }
  }
}

@media (max-width: $mobile-width) {
  .icon-wrapper {
    font-size: 0.95em;
    flex-direction: column;
    align-items: center;
    min-width: unset;
    height: 150px;
    width: 150px;
    text-align: center;

    .icon-wrapper-icon {
      padding: 0.5em;
    }
    .icon-wrapper-text {
      padding: 0.5em 1em;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
