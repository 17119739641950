.address-searcher {
  margin-bottom: 1em;
  width: 60%;
  margin-left: auto;
  border: 1px solid black;
  border-radius: 2px;
  max-height: 300px;
  overflow-y: auto;

  .address-suggestion {
    padding: 0.25em 0;
    transition: 0.3s ease;
    padding-left: 1.5em;

    &:hover {
      background-color: $light-blue-color;
      color: $light-bg-color;
      cursor: pointer;
    }
  }
}
