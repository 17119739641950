.auth-page {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: $main-theme-color;
  opacity: 0.95;

  ::selection {
    background-color: $lighter-blue-color;
  }

  .main-content {
    margin: auto;
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logos-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      img {
        margin-bottom: 3em;
      }
    }

    .form-wrapper {
      width: 40%;

      .form-signin, .form-reset-password {
        width: 100%;
        padding: 2.5em;
        background-color: $light-bg-color;
        border-radius: 5px;

        h1 {
          font-size: 2.5em;
          text-align: center;
          margin-bottom: 1em;
        }

        .extra-actions {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
        }
      }
    }
  }
}

@media (max-width: $laptop-width) {
  .auth-page {
    .main-content {
      flex-direction: column;
      width: 80%;

      .logos-section,
      .form-wrapper {
        margin-top: 1em;
        width: 100%;
      }
    }
  }
}

.checkbox {
  margin-left: 10px;
  font-weight: normal;
}

.hide {
  display: none;
}
