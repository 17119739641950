.agreement-links {
  margin-top: auto;
  font-size: 0.8em;
  padding: 1.5em 15px;
  background: $darkest-grey-color;

  a {
    display: block;
    color: $light-text-color;
    text-decoration: underline;

    &:visited {
      color: $light-text-color;
    }
  }
}
