.quick-order-card {
  background-color: $light-bg-color;
  padding: 2em;
  padding-bottom: 100px;
  margin-bottom: 2em;

  .quick-order-order-form {
    margin-left: auto;
    width: 75%;

    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }
}
