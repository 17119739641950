$main-theme-color: #003456;

$light-text-color: white;
$grey-text-color: #aaa;
$darker-grey-text-color: #888;
$dark-text-color: black;

$light-bg-color: white;
$lighter-grey-bg-color: #dcdcdc;
$dark-bg-color: black;

$dark-grey-color: #555;
$darker-grey-color: #444;
$darkest-grey-color: #333;

$light-border-color: #aaa;
$lighter-border-color: #eee;

$light-blue-color: #2380a7;
$lighter-blue-color: #7793ab;

$body-bg-color: #eeeeee;

$success-color: #b6f8c4;
$warning-color: #fae7c7;
$danger-color: #721c24;
$standout-text-color: #ff6600;
