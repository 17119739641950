.order-summary {
  display: flex;
  align-items: flex-start;

  .icon-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .icon-container-with-text {
      margin-right: 1em;
      margin-bottom: 1em;

      small {
        float: right;
      }
    }

    .counter {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.order-details-container {
  display: flex;
  justify-content: space-between;

  .extra-info-container {
    width: 50%;
    background-color: $light-bg-color;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    a {
      margin: 0 0 1em 1em;
    }

    table {
      margin-bottom: 1em;

      th,
      td {
        padding: 1em 0 0 1em;
        vertical-align: top;
      }
    }
  }
}

@media (min-width: 1501px) {
  .order-summary {
    .counter {
      margin-left: auto;
    }
  }
}

@media (max-width: $laptop-width) {
  .order-summary {
    flex-direction: column;
  }
}

@media (max-width: $mobile-width) {
  .order-summary {
    flex-direction: column;

    .icon-group {
      width: 100%;

      .icon-container-with-text {
        &.counter {
          margin-left: 0;
        }

        small {
          font-size: 0.7em;
        }
      }
    }
  }
}
