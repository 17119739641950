.store-order-card {
  font-size: 15px;
  background-color: white;
  margin-bottom: 1em;
  border: 1px solid $light-border-color;

  &.open {
    border: 2px solid $lighter-blue-color;
  }

  :hover > .brief,
  &.open .brief {
    color: $light-text-color;
    background-color: $lighter-blue-color;
  }

  .accpac-order-id {
    font-weight: bold;
    flex-basis: 15%;

    .status {
      color: $standout-text-color;
    }
    .error {
      color: $standout-text-color;
    }
  }

  .po-number {
    flex-basis: 20%;
  }

  .freight {
    flex-basis: 20%;
  }

  .status-tag {
    flex-basis: 20%;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    color: $light-text-color;

    .invoiced {
      width: 180px;
      background-color: $success-color;
      color: $dark-text-color;
    }

    .check {
      width: 180px;
      background-color: $lighter-grey-bg-color;
      color: $dark-text-color;
    }
  }

  .back-order {
    flex-basis: 20%;
    color: $light-text-color;

    div {
      text-align: center;
      font-weight: bold;
      background-color: $dark-bg-color;
      width: 50px;
    }
  }

  .submitted {
    flex-basis: 25%;
  }

  .brief {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.4em 0.8em;
    min-height: 50px;
    transition: all 0.3s ease;
  }
}
