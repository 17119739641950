.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #003456;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  z-index: 1000;
  font-size: 12px;
  opacity: 1;
  pointer-events: none;
}

.tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
}

.top::before {
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px 5px 0 5px;
  border-color: #003456 transparent transparent transparent;
}

.bottom::before {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #003456 transparent;
}

.left::before {
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #003456;
}

.right::before {
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 5px 5px 5px 0;
  border-color: transparent #003456 transparent transparent;
}

.top {
  bottom: 140%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  top: 100%; /* Position below the element */
  left: 50%;
  transform: translateX(-50%);
}

.left {
  right: 100%; /* Position to the left of the element */
  top: 50%;
  transform: translateY(-50%);
}

.right {
  left: 110%; /* Position to the right of the element */
  top: 50%;
  transform: translateY(-50%);
}
