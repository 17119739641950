.saved-order-card {
  margin-bottom: 1em;
  background-color: $light-bg-color;
  border: 1px solid $light-border-color;

  .saved-order-card-preview {
    padding: 1em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: 0.3s ease;

    &:hover,
    &.is-open {
      color: $light-text-color;
      background-color: $lighter-blue-color;
    }

    .preview-line {
      display: inline;
    }

    button {
      &:hover {
        background-color: white;
      }
    }
  }

  .saved-order-main-content {
    display: flex;
    flex-direction: column;
  }

  .modal-template {
    padding: 2em;
    text-align: center;
    background-color: $light-bg-color;
    border: 1px solid $light-border-color;
    margin: auto;
  }
}
