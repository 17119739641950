header.header {
  color: $light-text-color;

  .notification-bar {
    background-color: $warning-color;
    padding: 1em;
    text-align: center;
    color: black;

    a {
      margin: 0 1em;
      color: black;
      text-decoration: underline;
    }
  }

  nav {
    background-color: $main-theme-color;
    display: flex;
    padding: 1em 0;
    justify-content: space-between;

    .logo {
      display: inline-block;
      margin-left: 1em;
      cursor: pointer;

      img {
        width: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    .actions {
      display: flex;
      padding-right: 1em;

      .action {
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
        margin-left: 2em;

        .action-link {
          color: $light-text-color;
        }
      }
    }
  }

  .secondary-nav {
    padding: 1em;
    background-color: $light-bg-color;
    position: relative;

    // custom search bar component
    .search-bar {
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: $mobile-width) {
        width: 80%;
      }
    }

    .menu-opener {
      display: flex;
      cursor: pointer;
      transition: 0.5s ease;
      width: min-content;
      padding: 0.5em 1em;

      &:hover {
        background-color: $lighter-grey-bg-color;
      }

      span {
        color: $darker-grey-text-color;
        margin-left: 0.5em;
      }
    }
  }

  @media (max-width: $laptop-width) {
    nav {
      .logo {
        width: 40%;
      }
    }
  }

  @media (max-width: $mobile-width) {
    nav {
      flex-direction: column;
      align-items: center;

      .logo {
        margin-bottom: 1em;
        width: 70%;
      }

      .actions {
        font-size: 0.9em;
      }
    }
  }
}
