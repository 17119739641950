.custom-alert {
  width: fit-content;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.custom-alert-success {
    background-color: rgba(182, 248, 196, 0.8);
    border: 1px solid rgba(182, 248, 196, 1);
  }

  &.custom-alert-warning {
    background-color: rgba(250, 231, 199, 0.8);
    border: 1px solid rgba(250, 231, 199, 1);
  }

  &.custom-alert-error {
    background-color: rgba(114, 28, 36, 0.8);
    border: 1px solid rgba(114, 28, 36, 1);
    color: white;
  }

  &.custom-alert-info {
    background-color: rgba(220, 220, 220, 0.8);
    border: 1px solid rgba(220, 220, 220, 1);
  }
}
