.shipping-page {
  margin-bottom: 1em;

  h4 {
    margin: 1em 0;
  }

  .new-address-wrapper {
    border: 1px solid $light-blue-color;
    background-color: $light-bg-color;
    padding: 2em;
  }

  .shippingAddressForm {
    max-width: 900px;
    margin-bottom: 1em;
  }

  .hide {
    display: none;
  }

  .bar {
    padding-left: 10px;
    padding-right: 10px;
  }

  a:hover {
    text-decoration: none;
  }
}
