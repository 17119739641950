.store-order-detail {
  p {
    margin: 0;
  }

  .upper {
    background-color: rgb(234, 233, 233);
    padding: 0.4em 0.8em;
  }

  .upper-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payment-modal {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $light-bg-color;
      height: 20%;
      width: 30%;
      margin: auto;
      border: 1px solid $light-border-color;

      .payment-modal-select {
        display: flex;

        // .payment-modal-option-select {
        //   background-color: $lighter-grey-bg-color;
        // }

        // div {
        //   padding: 0.2rem 0.5rem;
        //   border: 1px solid $light-border-color;
        // }
      }

      .payment-modal-input {
        display: flex;
        flex-direction: column;
        padding: 1em 0em;

        .error-message {
          color: red
        }
        .error-input {
          border: 2px solid red;
        }
      }

      .payment-modal-buttons {
        align-self: flex-end;

        button {
          margin: 0 1rem;
        }
      }

    }

    .group-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pay-order-button {
        position: relative;
        margin-left: 1em;
      }

      .tooltip {
        width: 10rem;
        text-align: center;
      }

      .pay-order-content {
        display: none;
        position: absolute;
        right: 0;
        z-index: 1;
        min-width: 13rem;

        button {
          float: right;
        }
      }

      .pay-order-dropdown:hover .pay-order-content {
        display: block;
      }

      .success-confirming {
        background-color: orange;
        border-radius: 1.5em;
        color: white;
        padding: 0.65em;
      }

      .success-payment {
        background-color: yellowgreen;
        border-radius: 1.5em;
        color: white;
        padding: 0.5em;
        margin-left: 0.5em;
      }

      .pay-order-button {
        margin-left: 0.5em;
      }
    }

    th {
      min-width: 250px;
    }
    .order-status {
      font-size: 1.4em;
      color: rgb(68, 160, 255);
    }
  }

  .lower {
    padding: 0.4em 0.8em;
  }

  .comment,
  .ship-to {
    flex-basis: 50%;
  }

  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .order-costs {
    display: flex;

    .order-costs-row {
      display: flex;
      margin-left: auto;

      .order-costs-column {
        margin: 0 0 0 3rem;
        margin-top: 1em;
        width: auto;

        div {
          width: 100%;
          display: flex;
          justify-content: space-between;

          span {
            padding-left: 2em;
          }
        }
      }

      .order-costs-column-au {
        margin-left: auto;
        margin-top: 1em;

        width: fit-content;

        div {
          width: 100%;
          display: flex;
          justify-content: space-between;

          span {
            padding-left: 2em;
          }
        }
      }

    }
  }

  .payment-info {
    color: red;
    text-align: end;
  }
}
