.preferences {
  .preferences-sub-section {
    border: 1px solid $light-border-color;
    background-color: $light-bg-color;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: space-between;
      padding-left: 1em;
      border-bottom: 1px solid black;

      h5 {
        padding-top: 0.5em;
      }
    }

    .main-content {
      padding: 1em;

      &.forms {
        width: 50%;
        margin: auto;

        @media (max-width: $mobile-width) {
          width: 95%;
        }
      }
    }
  }
}
