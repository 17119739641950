.timed-out-modal {
  background-color: $light-bg-color;
  padding: 3em 5em;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $light-border-color;

  p {
    margin: 1em 0;
  }

  button {
    align-self: stretch;
  }
}
