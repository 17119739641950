table.import-table {
  width: 85%;
  margin-top: 1em;
  height: fit-content;

  .reload-order-item {
    td {
      padding: 1em 0;

      &:last-child {
        text-align: left;
      }
    }
  }

  .table-heading {
    text-align: center;
    margin: 0 auto;
    font-size: 1.5em;

    &:nth-child(2) {
      padding: 2em 0;
    }

    &.failed {
      padding: 0;
      color: red;
    }
  }
}
