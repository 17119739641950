.address-book-editor {
  width: 100%;
  margin: 0 auto;

  table {
    width: 100%;

    th,
    td {
      padding-bottom: 1em;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 auto;

    button:first-child {
      margin: 1em 0;
    }
  }
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}