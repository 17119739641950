.modal-overlay {
  height: 100%;
  width: 100%;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
}
