.cart-item-table {
  width: 100%;

  th,
  td {
    text-align: center;
  }

  .description {
    text-align: left;
  }
}
