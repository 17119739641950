.order-history-wrapper {
  padding: 2rem 0 0 0;
}

.order-history-button {
  display: 'flex';
  flex-direction: 'row';
  padding: 10px 0 20px 0;
}

.ponumber-unavailable-card {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 1px solid $light-border-color;
  font-size: 15px;
  margin-top: 1em;
  padding: 4em;
}
