.loader {
  border: 3px solid $lighter-grey-bg-color;
  border-top: 3px solid $main-theme-color;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2em 0;
}