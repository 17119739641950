.colorTile {
  width: 110px;
  margin-right: 15px;
  margin-bottom: 1em;
  background-color: $light-bg-color;
  height: fit-content;

  .color-selector-container {
    position: absolute;
    top: -12px;
    left: -8px;

    .plane-svg {
      width: 30px;
      height: 29px;
      fill: white;
      background-color: #003456;
      padding: 6px;
      border-radius: 50%;
    }
    .tooltip {
      width: 20rem;
    }
  }

  .colorName {
    bottom: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: small;
  }

  .colorSelector {
    border: 2px solid white;
    text-align: center;
    margin-bottom: 2px;
    position: relative;
    height: 180px;
    box-sizing: content-box;

    .selected-item-status-icon {
      position: absolute;
      right: 5px;
      top: 0;
      z-index: 1;

      .loader {
        margin-top: 2px;
      }
    }

    &:hover {
      cursor: pointer;
      border: 2px solid $main-theme-color;
    }

    &.selected {
      border: 2px solid $main-theme-color;
    }

    &.success {
      border: 2px solid $success-color;
    }

    &.fail {
      border: 2px solid $danger-color;
    }
  }
}

.previewImage {
  width: 500px;
  display: block;
  margin: auto;
}

.fbModal {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.add-to-cart-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;

  div {
    display: flex;

    input {
      width: fit-content;
      margin-right: 1em;
      height: fit-content;
    }

    button {
      margin-right: 1em;
      height: fit-content;
    }

  }

  @media (max-width: $laptop-width) {
    flex-direction: column;
    align-items: flex-start;
  }

}






